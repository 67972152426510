html, body, #root, .App {
  height: 100%;
}
.App{
  display: flex;
  flex-direction: column;

}
.content{
  flex: 1 0 auto;
}
#footer{
  flex-shrink: 0;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.instructions{
  margin-top: 10rem;
  text-align: left;
}

a{
  text-decoration: none;
  color: #0033a1;
}

.continue-button{
  text-align: center;
}
.ql-editor {
  min-height: 10rem;
}
